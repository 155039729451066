import "./map.styles.scss";

const Map = () => {
  return (
    <section id="map" className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.3151131673435!2d-51.21543472358459!3d-30.027816330507115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95197943e86ac2a1%3A0xa1de599be5ec00b2!2sNina%20Caf%C3%A9%20de%20Casa!5e0!3m2!1spt-BR!2sbr!4v1702682132729!5m2!1spt-BR!2sbr"
        loading="lazy"
        title="Mostrando no mapa a rua Gonçalo de Carvalho 129 - Floresta, Porto Alegre - RS"
      ></iframe>
    </section>
  );
};

export default Map;
