import "./input.styles.scss";

const Input = ({ text, styles, onChangeHandler, searchInputRef }) => {
  const { width, color, ...rest } = styles;

  return (
    <div
      className="input"
      style={{
        width,
      }}
    >
      <div className="input-field">
        <input
          required
          style={{
            ...rest,
          }}
          onChange={onChangeHandler}
          ref={searchInputRef}
        />
        <span className="input-label" style={{ color }}>
          {text}
        </span>
      </div>
    </div>
  );
};

export default Input;
