import Tips from "../tips/tips.component";
import Navigation from "../navigation/navigation.component";

import "./header.styles.scss";

const Header = ({ linkList }) => {
  return (
    <header>
      <Tips />
      <Navigation linkList={linkList} />
    </header>
  );
};

export default Header;
