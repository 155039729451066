import Product from "../product/product.component";

import "./product-list.styles.scss";

const ProductList = ({ products, modalRef, setModalProduct }) => {
  products.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });

  return (
    <div className="product-list">
      {products.map((product) => {
        return (
          <Product
            key={product.id}
            product={product}
            modalRef={modalRef}
            setModalProduct={setModalProduct}
          />
        );
      })}
    </div>
  );
};

export default ProductList;
