import SubCategoryList from "../sub-category-list/sub-category-list.component";
import emptyHeart from "../../assets/components/products/coffee-shaped-heart-empty.svg";
import fullHeart from "../../assets/components/products/coffee-shaped-heart-full.svg";

import "./modal.styles.scss";

const Modal = ({ modalRef, modalProduct }) => {
  const {
    image,
    name,
    strength,
    price,
    description,
    ingredients,
    flavors,
    options,
    subCategories,
  } = modalProduct;

  const onCloseHandler = () => {
    modalRef.current.classList.add("modal--close");
  };

  const divOptions =
    Array.isArray(options) && options.length > 0 ? (
      <div className="modal__content-body-options">
        <span className="modal__content-body-options-title">Opções</span>
        <ul>
          {options.map((option) => {
            return (
              <li key={option.id}>
                <div className="modal__content-body-options-option-name">
                  {option.name}
                </div>
                <div className="modal__content-body-options-option-price">
                  R$: {Number(option.price).toFixed(2)}
                </div>
                {option.description && (
                  <div className="modal__content-body-options-option-description">
                    <span className="modal__content-body-options-option-description-title">
                      Descrição:{" "}
                    </span>
                    {option.description}
                  </div>
                )}
                {Array.isArray(option.flavors) && option.flavors.length > 0 ? (
                  <div className="modal__content-body-options-option-flavors">
                    <span className="modal__content-body-options-option-flavors-title">
                      Sabores:{" "}
                    </span>
                    {option.flavors.join(", ")}
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    ) : null;

  let coffeStrength = [];
  const MAX_COFFEE_STRENGTH = 5;
  for (let i = 0; i < MAX_COFFEE_STRENGTH; i += 1) {
    if (i < strength) {
      coffeStrength.push(
        <img
          key={i}
          src={fullHeart}
          alt="Logo"
          className="modal__content-body-strength-image"
        />
      );
    } else {
      coffeStrength.push(
        <img
          key={i}
          src={emptyHeart}
          alt="Logo"
          className="modal__content-body-strength-image"
        />
      );
    }
  }

  const divStrength = Array.isArray(subCategories) &&
    subCategories.includes("Café") && (
      <div className="modal__content-body-strength">
        <span className="modal__content-body-strength-title">
          Intensidade:{" "}
        </span>
        {coffeStrength}
      </div>
    );

  return (
    <div className="modal modal--close" ref={modalRef}>
      <div className="modal__background" onClick={onCloseHandler}></div>

      <div className="modal__content">
        {image ? (
          <div
            className="modal__content-header-image"
            style={{
              backgroundImage: `url("${image}")`,
            }}
          ></div>
        ) : (
          <div className="modal__content-header-image"></div>
        )}
        <label className="modal__content-close-button" onClick={onCloseHandler}>
          <span></span>
        </label>
        <div className="modal__content-body">
          <div className="modal__content-body-name">{name}</div>
          {divStrength}
          {price && (
            <div className="modal__content-body-price">
              R$: {Number(price).toFixed(2)}
            </div>
          )}
          {description ? (
            <div className="modal__content-body-description">
              <span className="modal__content-body-description-title">
                Descrição
              </span>
              {description}
            </div>
          ) : null}
          {ingredients ? (
            <div className="modal__content-body-ingredients">
              <span className="modal__content-body-ingredients-title">
                Ingredientes
              </span>
              {ingredients}
            </div>
          ) : null}
          {Array.isArray(flavors) && flavors.length > 0 ? (
            <div className="modal__content-body-flavors">
              <span className="modal__content-body-flavors-title">Sabores</span>
              <ul>
                {flavors.map((flavor) => {
                  return (
                    <li className="modal__content-body-flavor" key={flavor}>
                      {flavor}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
          {divOptions}
          <div className="modal__content-body-sub-categories">
            <SubCategoryList subCategories={subCategories} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
