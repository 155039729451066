import { Fragment } from "react";

import ProductList from "../product-list/product-list.component";
import LoadingLogo from "../loading-logo/loading-logo.component";

import "./category.styles.scss";

const Category = ({ isLoading, categories, modalRef, setModalProduct }) => {
  const categoriesWithProducts = categories.filter(
    (category) => category.products.length !== 0
  );

  let content;
  if (categoriesWithProducts.length > 0) {
    content = (
      <>
        {categories.map(({ id, name, products }) => {
          return (
            Array.isArray(products) &&
            products.length > 0 && (
              <Fragment key={id}>
                <h3 className="category-title">{name}</h3>
                <ProductList
                  products={products}
                  modalRef={modalRef}
                  setModalProduct={setModalProduct}
                />
              </Fragment>
            )
          );
        })}
      </>
    );
  } else {
    content = <h3 className="category-title">Nenhum produto encontrado 😖</h3>;
  }

  return <>{isLoading ? <LoadingLogo /> : content}</>;
};

export default Category;
