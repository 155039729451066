import image from "../../assets/components/history/history.jpg";

import "./history.styles.scss";

const History = () => {
  const paragraphs = [
    {
      id: 1,
      text: "A história do Nina Café de Casa inicia a muitos anos atrás, quando iniciei minha jornada profissional no setor no qual dediquei os últimos 22 anos: gestão de pessoas. Foi durante um estágio em uma empresa que percebi minha afinidade com cafeterias, despertando meu interesse por esse universo",
    },
    {
      id: 2,
      text: "Ao longo dos anos, mesmo durante os altos e baixos da vida profissional, o sonho de ter minha própria cafeteria sempre permaneceu presente. No entanto, foi apenas em 2020, com o início da pandemia e a consequente expansão do home office, que percebi a necessidade de locais adequados para aqueles que trabalham remotamente em Porto Alegre. Foi então, somado ao anúncio de venda do Poza Café, um estabelecimento localizado no bairro que chamo de lar há mais de 12 anos, que decidi que era hora de perseguir meu sonho",
    },
    {
      id: 3,
      text: "O caminho até aqui foi repleto de desafios. Desde encontrar a coragem necessária até garantir o investimento inicial, desenvolver o nome, o cardápio e estabelecer as estratégias de gestão e contratação. No entanto, cada obstáculo foi superado, graças ao apoio incansável de amigos e familiares. Hoje, o Nina Café de Casa é uma realidade graças a todos que se envolveram e contribuíram para transformar esse sonho em algo tangível",
    },
    {
      id: 4,
      text: "Agora, oferecemos um espaço que visa proporcionar experiências memoráveis em um ambiente confortável e acolhedor. Seja para aqueles que estão apenas de passagem, para estudantes que precisam de um local tranquilo para se concentrar, ou para profissionais que optam por trabalhar remotamente, nós nos preocupamos em oferecer uma infraestrutura completa, com acesso Wi-Fi de alta qualidade e diversas tomadas disponíveis. Além disso, muitas as receitas do nosso cardápio são cuidadosamente preparadas com base nas tradicionais e amadas receitas de minha mãe",
    },
    {
      id: 5,
      text: "Para o futuro, visualizamos o Nina Café de Casa como um espaço versátil, capaz de hospedar uma variedade de eventos na área externa, expandir para o segundo andar e até mesmo oferecer salas de trabalho inspiradoras. No entanto, nosso compromisso primordial será sempre manter a essência inicial do Nina: ser um ambiente acolhedor e familiar, onde cada cliente se sinta verdadeiramente em casa",
    },
  ];

  return (
    <section className="history" id="history">
      <h2 className="history__title">História</h2>
      <div className="standard-alignment">
        <div className="history__wrapper">
          <img
            className="history__image"
            src={image}
            alt="Du(dono do Nina) e sua mãe(Seni) estão atrás de um balcão com um pão caseiro feito por ele mesmo, e duas xícaras de café"
          />

          <div className="history__text">
            <div className="history__image-placeholder"></div>

            {paragraphs.map(({ id, text }) => {
              return <p key={id}>&emsp;{text}</p>;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;
