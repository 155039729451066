import { HashLink } from "react-router-hash-link";

import logo from "../../assets/components/logo/logo.svg";

import "./footer.styles.scss";

const Footer = ({ linkList }) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -128;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <footer>
      <div className="footer">
        <div className="footer__image-layer">
          <div className="standard-alignment">
            <div className="footer__content">
              <div className="footer__coluna footer__coluna--logo">
                <HashLink to={"/#"} scroll={(el) => scrollWithOffset(el)}>
                  <img className="footer__logo" src={logo} alt="Logo" />
                </HashLink>
              </div>
              <div className="footer__coluna">
                <h2 className="footer__title">Links</h2>
                <ul className="footer__list">
                  {linkList.map(
                    ({ id, section, path, isNewPage }) => {
                      if (isNewPage) {
                        return (
                          <li className="footer__list-item" key={id}>
                            <HashLink
                              className="footer__list-item__links"
                              to={path + "#"}
                              scroll={(el) => scrollWithOffset(el)}
                            >
                              {section}
                            </HashLink>
                          </li>
                        );
                      } else {
                        return (
                          <li className="footer__list-item" key={id}>
                            <HashLink
                              className="footer__list-item__links"
                              to={path}
                              scroll={(el) => scrollWithOffset(el)}
                            >
                              {section}
                            </HashLink>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
              <div id="contact" className="footer__coluna">
                <h2 className="footer__title">Endereço</h2>
                <ul className="footer__list">
                  <li className="footer__list-item footer__list-item--address">
                    Rua Gonçalo de Carvalho 129 - Porto Alegre - RS
                  </li>
                </ul>
                <h2 className="footer__title">Redes</h2>
                <ul className="footer__list">
                  <li className="footer__list-item footer__list-item--instagram">
                    <a
                      className="footer__list-item__links footer__list-item__links--instagram"
                      href="https://instagram.com/ninacafedecasa"
                      rel="noreferrer"
                      target="_blank"
                    >
                      ninacafedecasa
                    </a>
                  </li>
                  <li className="footer__list-item footer__list-item--facebook">
                    <a
                      className="footer__list-item__links footer__list-item__links--facebook"
                      href="https://www.facebook.com/people/Nina-Caf%C3%A9-de-Casa/61556020071514"
                      rel="noreferrer"
                      target="_blank"
                    >
                      ninacafedecasa
                    </a>
                  </li>
                  <li className="footer__list-item footer__list-item--spotify">
                    <a
                      className="footer__list-item__links footer__list-item__links--spotify"
                      href="https://open.spotify.com/playlist/2sI8hilhjXzUR2TJ2UI5HX?si=7OUFBOSZRNikrWs-evK4vg&pi=u-AJ3Q304dSmOn"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Nina Cafe de Casa
                    </a>
                  </li>
                  <li className="footer__list-item footer__list-item--whatsapp">
                    <a
                      className="footer__list-item__links footer__list-item__links--spotify"
                      href="https://api.whatsapp.com/send?phone=5551993336970"
                      rel="noreferrer"
                      target="_blank"
                    >
                      +55 (51) 9 9333-6970
                    </a>
                  </li>
                </ul>
                <h2 className="footer__title">Horário</h2>
                <ul className="footer__list">
                  <li className="footer__list-item footer__list-item--opening-hours">
                    Terça a Domingo - 09:00 às 19:00
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
