import { useState, useEffect, useRef } from "react";
import { INPUT, BUTTON, CATEGORIES } from "./constants";

import Button from "../../components/button/button.component";
import Category from "../../components/category/category.component";
import Input from "../../components/input/input.component";
import SubCategory from "../../components/sub-category/sub-category.component";
import Modal from "../../components/modal/modal.component";
import missingImage from "../../assets/missing-image.jpg";

import "./menu.styles.scss";

const removeSpecialCharacters = (word) => {
  const removeSpecialCharacter = (w) => {
    return w
      .replace(/[aáàãäâ]/, "a")
      .replace(/[eéèëê]/, "e")
      .replace(/[iíìïî]/, "i")
      .replace(/[oóòõöô]/, "o")
      .replace(/[uúùüû]/, "u")
      .replace(/[ç]/, "c");
  };

  return word
    .toLocaleLowerCase()
    .split(" ")
    .map((word) => removeSpecialCharacter(word))
    .join(" ");
};

const Menu = () => {
  const modalRef = useRef("");
  const [modalProduct, setModalProduct] = useState({
    id: 0,
    image: missingImage,
    name: "",
    strength: 0,
    price: 0,
    flavors: [],
    subCategories: [],
  });
  const searchInputRef = useRef("");
  const [searchString, setSearchString] = useState("");
  const [categories] = useState(CATEGORIES);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [isLoading, setIsLoading] = useState(true);

  const onSearchHandler = (event) => {
    setSearchString(event.target.value.toLocaleLowerCase());
  };

  const onClearHandler = () => {
    searchInputRef.current.value = "";
    setSearchString("");
  };

  const onSelectChange = (event) => {
    const subCategory = event.target.firstChild.data;
    if (subCategory) {
      searchInputRef.current.value = subCategory;
      setSearchString(subCategory.toLocaleLowerCase());
    }
  };

  useEffect(() => {
    const result = [];

    categories.forEach(({ products }) => {
      products.forEach(({ subCategories }) => {
        subCategories.forEach((subCategory) => {
          if (!result.includes(subCategory)) result.push(subCategory);
        });
      });
    });

    result.sort((a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    setSubCategories(result);
  }, [categories]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);

    const newFilteredCategories = categories.map((category) => {
      const newCategory = {
        id: category.id,
        name: category.name,
      };

      newCategory.products = category.products.filter((product) => {
        let matchWithSomething = false;

        if (product.flavors) {
          product.flavors.map((flavor) => {
            matchWithSomething =
              matchWithSomething ||
              removeSpecialCharacters(flavor).includes(
                removeSpecialCharacters(searchString)
              );

            return matchWithSomething;
          });
        }

        if (product.options) {
          product.options.map(({ name }) => {
            matchWithSomething =
              matchWithSomething ||
              removeSpecialCharacters(name).includes(
                removeSpecialCharacters(searchString)
              );

            return matchWithSomething;
          });
        }

        product.subCategories.map((subCategory) => {
          matchWithSomething =
            matchWithSomething ||
            removeSpecialCharacters(subCategory).includes(
              removeSpecialCharacters(searchString)
            );

          return matchWithSomething;
        });

        return (
          removeSpecialCharacters(product.name).includes(
            removeSpecialCharacters(searchString)
          ) || matchWithSomething
        );
      });

      return newCategory;
    });

    setFilteredCategories(newFilteredCategories);
  }, [categories, searchString]);

  return (
    <div className="menu">
      <div className="standard-alignment">
        <h2 className="menu__title">Cardápio</h2>
        <div className="menu__search">
          <Input
            text={INPUT.text}
            styles={INPUT.styles}
            onChangeHandler={onSearchHandler}
            searchInputRef={searchInputRef}
          />
          <Button
            text={BUTTON.text}
            styles={BUTTON.styles}
            onClickHandler={onClearHandler}
          />
        </div>
        <div className="menu__tips">
          <h3 className="menu__tips-title">Sugestões de busca</h3>
          <div className="menu__tips-sub-categories">
            {subCategories.map((name) => {
              return (
                <div
                  key={name}
                  className="menu__tips-sub-category"
                  onClick={onSelectChange}
                >
                  <SubCategory name={name} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="menu__category">
          <Category
            isLoading={isLoading}
            categories={filteredCategories}
            modalRef={modalRef}
            setModalProduct={setModalProduct}
          />
        </div>
      </div>
      <Modal modalRef={modalRef} modalProduct={modalProduct} />
    </div>
  );
};

export default Menu;
