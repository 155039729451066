import { Outlet } from "react-router-dom";

import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";

import "./template.styles.scss";
import StaticWhatsapp from "../../components/static-whatsapp/static-whatsapp.component";

const Template = () => {
  const links = [
    { id: 1, section: "Início", path: "/", isNewPage: true, isSelected: true },
    {
      id: 2,
      section: "Sobre",
      path: "/#about",
      isNewPage: false,
      isSelected: false,
    },
    {
      id: 3,
      section: "História",
      path: "/#history",
      isNewPage: false,
      isSelected: false,
    },
    {
      id: 7,
      section: "Mídia",
      path: "/#media",
      isNewPage: false,
      isSelected: false,
    },
    {
      id: 4,
      section: "Mapa",
      path: "/#map",
      isNewPage: false,
      isSelected: false,
    },
    {
      id: 5,
      section: "Contato",
      path: "/#contact",
      isNewPage: false,
      isSelected: false,
    },
    {
      id: 6,
      section: "Cardápio",
      path: "/menu",
      isNewPage: true,
      isSelected: false,
    },
  ];

  const removeIds = (links, ids) => {
    const result = links.filter((link) => !ids.includes(link.id));

    return result;
  };

  return (
    <>
      <Header linkList={removeIds(links, [2, 3, 4, 7])} />
      <main>
        <Outlet />
      </main>
      <StaticWhatsapp />
      <Footer linkList={links} />
    </>
  );
};

export default Template;
