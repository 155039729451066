import { IMAGES } from "./constants";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./carosel.styles.scss";

const Carosel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="carosel">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={4000}
        infinite={true}
      >
        {IMAGES.map(({ id, image }) => {
          return (
            <div
              key={id}
              className="carosel__image"
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default Carosel;
