import "./button.styles.scss";

const Button = ({ text, styles, onClickHandler }) => {
  return (
    <button
      className="button"
      onClick={onClickHandler}
      style={{
        ...styles,
      }}
    >
      {text}
    </button>
  );
};

export default Button;
