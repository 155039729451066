export const TIPS = {
  content: {
    tips: [
      "Temos opções vegetarianas disponíveis",
      "Traga seu pet para passear, somos pet friendly",
      "Disponibilizamos tomadas tanto no ambiente interno quanto externo",
      "O Jardim está sempre aberto, sinta-se a vontade para conhecê-lo",
      "A Nina, dona do estabelecimento, nem sempre está no local",
    ],
  },
};
