import { HashLink } from "react-router-hash-link";
import { useRef } from "react";

import logo from "../../assets/components/logo/logo.svg";

import "./navigation.styles.scss";

const Navigation = ({ linkList }) => {
  const menuButtonRef = useRef("");

  const scrollWithOffset = (el) => {
    menuButtonRef.current.checked = false;
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -128;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="navigation">
      <div className="standard-alignment">
        <nav className="navigation__content">
          <HashLink to={"/#"} scroll={(el) => scrollWithOffset(el)}>
            <img className="logo" src={logo} alt="Logo" />
          </HashLink>
          <input
            id="hamburger"
            className="navigation__menu-button"
            type="checkbox"
            ref={menuButtonRef}
          />
          <label htmlFor="hamburger" className="navigation__menu-button-label">
            <span></span>
          </label>
          <ul className="navigation__list">
            {linkList.map(({ id, section, path, isNewPage, isSelected }) => {
              if (isNewPage) {
                return (
                  <li key={id}>
                    <HashLink
                      className="navigation__list-item"
                      to={path + "#"}
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      {section}
                    </HashLink>
                  </li>
                );
              } else {
                return (
                  <li key={id}>
                    <HashLink
                      className="navigation__list-item"
                      to={path}
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      {section}
                    </HashLink>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
