import carosel1 from "../../assets/components/carosel/carosel1-fhd.jpg";
import carosel2 from "../../assets/components/carosel/carosel2-fhd.jpg";
import carosel3 from "../../assets/components/carosel/carosel3-fhd.jpg";
import carosel4 from "../../assets/components/carosel/carosel4-fhd.jpg";
import carosel5 from "../../assets/components/carosel/carosel5-fhd.jpg";
import carosel6 from "../../assets/components/carosel/carosel6-fhd.jpg";

export const IMAGES = [
  {
    id: 1,
    image: carosel1,
  },
  {
    id: 2,
    image: carosel2,
  },
  {
    id: 3,
    image: carosel3,
  },
  {
    id: 4,
    image: carosel4,
  },
  {
    id: 5,
    image: carosel5,
  },
  {
    id: 6,
    image: carosel6,
  },
];
