import "./media.styles.scss";

const Media = () => {
  const media = [
    {
      id: 1,
      link: "https://www.jornaldocomercio.com/ge2/noticias/2024/01/1139046-com-refil-de-cafe-cafeteria-abre-na-rua-mais-bonita-de-porto-alegre.html",
      source: "Jornal do Comércio",
      title:
        "Com refil de café, negócio abre na rua mais bonita de Porto Alegre",
      sumary:
        "O principal objetivo do Nina Café de Casa é oferecer uma culinária caseira e acolhedora para a clientela. Uma cafeteria para se sentir em casa",
      image:
        "https://www.jornaldocomercio.com/_midias/jpg/2024/01/12/240112_6f_1500_eo_nina__9_-10443119.jpg",
    },
  ];

  return (
    <section className="media" id="media">
      <h2 className="media__title">O Nina na mídia</h2>
      <div className="standard-alignment">
        <div className="media__posts">
          {media.map(({ id, link, source, title, image, sumary }) => {
            return (
              <a href={link} rel="noreferrer" target="_blank" key={id}>
                <div className="media__post">
                  <div className="media__post-header">
                    <h3 className="media__post-header-source">{source}</h3>
                    <img
                      className="media__post-header-image"
                      src={image}
                      alt="Du(dono do Nina) na frente do Nina Café de Casa com a Nina(cadela inspiração para o nome) no colo"
                    />
                  </div>
                  <div className="media__post-body">
                    <h4 className="media__post-body-title">{title}</h4>
                    <p className="media__post-body-sumary">{sumary}</p>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Media;
