import { Routes, Route } from "react-router-dom";

import Home from "./routes/home/home.component";
import Template from "./routes/template/template.component";
import Menu from "./routes/menu/menu.component";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Template />}>
        <Route index element={<Home />} />
        <Route path="/menu" element={<Menu />} />
      </Route>
    </Routes>
  );
};

export default App;
