import whatsappLogo from "../../assets/components/static-whatsapp/whatsapp.svg";

import "./static-whatsapp.styles.scss";

const StaticWhatsapp = () => {
  return (
    <a
      className="static-whatsapp"
      href="https://api.whatsapp.com/send?phone=5551993336970"
      rel="noreferrer"
      target="_blank"
    >
      <img src={whatsappLogo} alt="Logo do whatsapp" className="static-whatsapp__content" />
    </a>
  )
}

export default StaticWhatsapp;
