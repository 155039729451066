import Carosel from "../../components/carosel/carosel.component";
import About from "../../components/about/about.component";
import History from "../../components/history/history.component";
import Media from "../../components/media/media.component";
import Map from "../../components/map/map.component";

import "./home.styles.scss";

const Home = () => {
  return (
    <>
      <Carosel />
      <div className="home__content">
        <About />
        <History />
        <Media />
      </div>
      <Map />
    </>
  );
};

export default Home;
