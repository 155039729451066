import { TIPS } from "./constants";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./tips.styles.scss";

const Tips = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="tips">
      <div className="standard-alignment">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={4000}
          infinite={true}
          arrows={false}
        >
          {TIPS.content.tips.map((tip, index) => {
            return (
              <p className="tips__text" key={index}>
                {tip}
              </p>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Tips;
