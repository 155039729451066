import product1 from "../../assets/components/products/espresso.jpg";
import product3 from "../../assets/components/products/ristreto.jpg";
import product4 from "../../assets/components/products/americano.jpg";
import product5 from "../../assets/components/products/coffee.jpg";
import product6 from "../../assets/components/products/cappuccino.jpg";
import product8 from "../../assets/components/products/mocaccino.jpg";
import product9 from "../../assets/components/products/carioca.jpg";
import product10 from "../../assets/components/products/cortado.jpg";
import product11 from "../../assets/components/products/pingado.jpg";
import product12 from "../../assets/components/products/affogato.jpg";
import product13 from "../../assets/components/products/caramel-iced-coffee.jpg";
import product14 from "../../assets/components/products/orange-tonic-espresso.jpg";
import product15 from "../../assets/components/products/frappe.jpg";
import product17 from "../../assets/components/products/cheesecake.jpg";
import product19 from "../../assets/components/products/seni.jpg";
import product20 from "../../assets/components/products/banoffee.jpg";
import product21 from "../../assets/components/products/brownie-chocolate-pie.jpg";
import product22 from "../../assets/components/products/nina.jpg";
import product23 from "../../assets/components/products/brownie.jpg";
import product27 from "../../assets/components/products/empada.jpg";
import product28 from "../../assets/components/products/quiche.jpg";
import product29 from "../../assets/components/products/pizza.jpg";
import product30 from "../../assets/components/products/brazilian-cheese-bread.jpg";
import product32 from "../../assets/components/products/bread.jpg";
import product33 from "../../assets/components/products/toast.jpg";
import product34 from "../../assets/components/products/lulu.jpg";
import product35 from "../../assets/components/products/maria.jpg";
import product36 from "../../assets/components/products/bears.jpg";
import product39 from "../../assets/components/products/aperol.jpg";
import product40 from "../../assets/components/products/caipirinha.jpg";
import product43 from "../../assets/components/products/gin-tonic.jpg";
import product44 from "../../assets/components/products/mojito.jpg";
import product45 from "../../assets/components/products/smirnoff-ice.jpg";
import product46 from "../../assets/components/products/irish-coffee.jpg";
import product48 from "../../assets/components/products/tea.jpg";
import product49 from "../../assets/components/products/hot-chocolate.jpg";
import product50 from "../../assets/components/products/frozen.jpg";
import product51 from "../../assets/components/products/ice-tea.jpg";
import product52 from "../../assets/components/products/soda.jpg";
import product53 from "../../assets/components/products/energy-drink.jpg";
import product54 from "../../assets/components/products/italian-soda.jpg";
import product56 from "../../assets/components/products/juice.jpg";
import product57 from "../../assets/components/products/water.jpg";
import product58 from "../../assets/components/products/chocolate-pie.jpg";
import product59 from "../../assets/components/products/mini-pizza.jpg";

export const INPUT = {
  text: "Buscar",
  styles: {
    width: "70%",
    color: "#d93250",
    border: "0.2em solid #d93250",
    borderRadius: "20px 0 0 20px",
  },
};

export const BUTTON = {
  text: "Limpar",
  styles: {
    width: "30%",
    color: "#e9f5eb",
    border: "0.2em solid #d93250",
    borderRadius: "0 20px 20px 0",
  },
};

export const CATEGORIES = [
  {
    id: 1,
    name: "Cafés",
    products: [
      {
        id: 1,
        name: "Espresso",
        image: product1,
        strength: 4,
        price: 7,
        description:
          "Uma dose de café preparado através da passagem de água quente, não fervente, sob alta pressão pelo café moído",
        options: [
          {
            id: 1,
            name: "Duplo",
            price: 10,
          },
        ],
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 3,
        name: "Ristreto",
        image: product3,
        strength: 5,
        price: 7,
        description: "Um shot da parte mais forte do café expresso",
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 4,
        name: "Americano",
        image: product4,
        strength: 2,
        description: "Uma dose de café(expresso ou coado) e água",
        price: 11,
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 5,
        name: "Coado/Passado",
        image: product5,
        strength: 1,
        price: 8,
        options: [
          {
            id: 1,
            name: "Refil",
            description: "Tome o quanto de café passado quiser durante 5 horas",
            price: 18,
          },
        ],
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 6,
        name: "Cappuccino",
        image: product6,
        strength: 3,
        options: [
          {
            id: 1,
            name: "Brasileiro",
            description:
              "Feito com solução em pó que contém café, leite em pó integral, cacau, açúcar e canela. Chantilly, (opcional)",
            price: 15,
          },
          {
            id: 2,
            name: "Italiano",
            description:
              "Expresso curto(uma dose de expresso), leite vaporizado com crema e canela polvilhada",
            price: 14,
          },
        ],
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 8,
        name: "Mocaccino",
        image: product8,
        strength: 3,
        price: 18,
        description:
          "Expresso curto(uma dose de expresso), leite vaporizado com crema, calda de chocolate e canela polvilhada. Chantilly, (opcional)",
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 9,
        name: "Carioca",
        image: product9,
        strength: 1,
        price: 10,
        description:
          "Expresso curto(uma dose de expresso) sem a primeira coagem",
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 10,
        name: "Cortado",
        image: product10,
        strength: 2,
        price: 12,
        description:
          "Uma expresso curto(uma dose de expresso) com leite vaporizado",
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 11,
        name: "Pingado",
        image: product11,
        strength: 2,
        price: 12,
        description: "Café coado com pouco leite",
        subCategories: ["Café", "Quente", "Sem álcool"],
      },
      {
        id: 12,
        name: "Affogato",
        image: product12,
        strength: 3,
        description:
          "Gelato de baunilha acompanhado de uma dose quente de café expresso",
        price: 16,
        subCategories: ["Café", "Gelado", "Sem álcool"],
      },
      {
        id: 13,
        name: "Gelado de Caramelo",
        image: product13,
        strength: 2,
        price: 19.9,
        description:
          "Leite integral, cappuccino, calda de caramelo, xarope de caramelo salgado e canela em pó. . Chantilly, (opcional)",
        subCategories: ["Café", "Gelado", "Sem álcool"],
      },
      {
        id: 14,
        name: "Espresso Tônica com Laranja",
        image: product14,
        strength: 4,
        price: 13,
        description:
          "Expresso curto(uma dose de expresso), água tônica, suco de laranja e gelo",
        subCategories: ["Café", "Gelado", "Sem álcool"],
      },
      {
        id: 15,
        name: "Frappê",
        image: product15,
        strength: 3,
        price: 19.9,
        description:
          "Expresso curto(uma dose de expresso), leite integralm, calda saborizada salgada e gelo",
        flavors: ["Caramelo", "Chocolate"],
        subCategories: ["Café", "Gelado", "Sem álcool"],
      },
      {
        id: 46,
        name: "Irish Coffee",
        image: product46,
        strength: 4,
        description:
          "Expresso curto(uma dose de expresso) e uma dose de whisky com chantilly e raspas de laranja",
        price: 28,
        subCategories: ["Bebida", "Café", "Com álcool"],
      },
    ],
  },
  {
    id: 2,
    name: "Delicias",
    products: [
      {
        id: 17,
        name: "Cheesecake",
        image: product17,
        price: 15,
        flavors: ["Frutas Vermelhas"],
        description:
          "Torta com base crocante de biscoito de maisena, com massa de cream cheese e coberta com geleia de frutas vermelhas",
        subCategories: ["Doce"],
      },
      {
        id: 19,
        image: product19,
        name: "Torta Seni",
        price: 18,
        description: "Pão de ló, amendoim com leite condensado e nata",
        subCategories: ["Doce"],
      },
      {
        id: 20,
        name: "Torta Banoffee",
        image: product20,
        price: 15,
        description:
          "Torta com base crocante de biscoito de maisena, banana, doce de leite e chantilly",
        subCategories: ["Doce"],
      },
      {
        id: 21,
        name: "Torta de Brownie de Chocolate",
        image: product21,
        price: 15,
        description:
          "Torta de massa de brownie vegano, ganache de chocolate 70% e cobertura de geleia de frutas vermelhas",
        subCategories: ["Doce", "Vegano"],
      },
      {
        id: 22,
        name: "Torta Nina",
        image: product22,
        price: 18,
        description:
          "Bolo de baunilha com recheio de quatro leites(leite condensado, creme de leite, leite coco e leite em pó) e brigadeiro de leite ninho",
        subCategories: ["Doce"],
      },
      {
        id: 23,
        name: "Brownie",
        image: product23,
        price: 7.5,
        description: "Massa de bolo de chocolate ao leite e meio amargo",
        flavors: ["Chocolate", "Chocolate com nozes"],
        options: [
          {
            id: 1,
            name: "Com sorvete",
            price: 20,
            description:
              "Massa de bolo de chocolate ao leite e meio amargo com uma bola de sorvete por cima",
            flavors: ["Creme"],
          },
        ],
        subCategories: ["Doce"],
      },
      {
        id: 27,
        name: "Empadas",
        image: product27,
        price: 14,
        description:
          "Massa quebradiça a base de manteiga com recheios variados",
        flavors: [
          "Cebola caramelizada",
          "Ricota e gorgonzola",
          "Camarão",
          "Carne de panela",
          "Frango",
          "Palmito",
        ],
        subCategories: ["Salgado"],
      },
      {
        id: 28,
        name: "Quiche",
        image: product28,
        price: 18,
        description:
          "Massa sablé clássica, recheio a base de ovos e creme de leite com toque de noz-moscada",
        flavors: [
          "Alho poró",
          "Brócolis",
          "Carne de panela",
          "Cogumelos",
          "Frango",
          "Palmito",
          "Tomate cereja com queijo colonial",
          "Gorgonzola com cebola caramelizada",
        ],
        subCategories: ["Salgado"],
      },
      {
        id: 29,
        image: product29,
        name: "Pizza",
        price: 39.9,
        description:
          "Massa fininha bem crocante com molho de tomate e queijo mussarela",
        flavors: ["Basca", "Calabresa", "Margherita", "Quatro queijos"],
        subCategories: ["Salgado"],
      },
      {
        id: 30,
        name: "Pão de queijo",
        image: product30,
        price: 5,
        subCategories: ["Salgado"],
      },
      {
        id: 31,
        image: product32,
        name: "Pão Caseiro",
        price: 4,
        description:
          "Fatia de pão caseiro para comer com uma geléia a sua escolha",
        flavors: ["Damasco", "Laranja", "Morango", "Pimenta"],
        options: [
          {
            id: 1,
            name: "Para levar",
            description: "Pão Caseiro inteiro(500g) para levar para casa",
            price: 20,
          },
        ],
        subCategories: ["Salgado"],
      },
      {
        id: 33,
        image: product33,
        name: "Torrada de pão caseiro",
        price: 18,
        description:
          "Torra feita com 2 fatias do delicioso pão caseiro e recheio a sua escolha",
        flavors: ["Queijo com salame", "Queijo com presunto"],
        subCategories: ["Salgado"],
      },
      {
        id: 34,
        name: "Torta Lulu",
        image: product34,
        price: 15,
        description:
          "Torta com base crocante de biscoito de maisena, mousse de limão com pedaços de chocolate branco e merengue suíço",
        subCategories: ["Doce"],
      },
      {
        id: 35,
        name: "Torta Maria",
        image: product35,
        price: 18,
        description: "Torta de pão de ló, brigadeiro de coco e pistache",
        subCategories: ["Doce"],
      },
      {
        id: 58,
        name: "Torta de Chocolate",
        image: product58,
        price: 18,
        description:
          "Torta feita em 8 camadas, sendo 4 delas com massa de bolo de chocolate e as outras 4 sendo recheio de brigadeiro de chocolate meio amargo",
        subCategories: ["Doce"],
      },
      {
        id: 59,
        name: "Mini pizza",
        image: product59,
        price: 16,
        description:
          "Massa de farinha de arroz integral, farinha de grão-de-bico, linhaça, amido de milho, batata e mandioca e azeite de oliva extra virgem",
        flavors: ["Alho-poró", "Margherita com pesto"],
        subCategories: ["Vegano"],
      },
    ],
  },
  {
    id: 3,
    name: "Bebidas",
    products: [
      {
        id: 36,
        name: "Cerveja",
        image: product36,
        options: [
          {
            id: 1,
            name: "Corona",
            price: 15,
          },
          {
            id: 2,
            name: "Eisenbahn IPA",
            price: 17,
          },
          {
            id: 3,
            name: "Heineken",
            price: 12,
          },
          {
            id: 4,
            name: "Heineken 0% álcool",
            price: 12,
          },
        ],
        subCategories: ["Bebida", "Com álcool"],
      },
      {
        id: 39,
        name: "Aperol Spritz",
        image: product39,
        price: 32,
        description: "Espumante prosecco, licor de aperol, laranja e gelo",
        subCategories: ["Bebida", "Com álcool"],
      },
      {
        id: 40,
        name: "Caipirinha",
        image: product40,
        description: "Suco de fruta, a fruta macerada, açucar e gelo",
        flavors: ["Limão"],
        options: [
          {
            id: 1,
            name: "Com cachaça",
            price: 22,
          },
          {
            id: 2,
            name: "Com Vodka Smirnoff",
            price: 24,
          },
          {
            id: 3,
            name: "Com Vodka Absolut",
            price: 27,
          },
        ],
        subCategories: ["Bebida", "Com álcool"],
      },
      {
        id: 43,
        name: "Gin tônica",
        image: product43,
        description: "Limão, água tônica, gin e gelo",
        price: 29,
        subCategories: ["Bebida", "Com álcool"],
      },
      {
        id: 44,
        name: "Mojito",
        image: product44,
        description: "Run, hortelã, suco de limão e água com gás",
        price: 27,
        subCategories: ["Bebida", "Com álcool"],
      },
      {
        id: 45,
        name: "Smirnoff Ice",
        image: product45,
        price: 15,
        subCategories: ["Bebida", "Com álcool"],
      },
      {
        id: 48,
        name: "Chá",
        image: product48,
        price: 8,
        flavors: [
          "Camomila",
          "Frutas silvestres",
          "Hortelã",
          "Limão com gengibre",
          "Chá verde e Hortelã",
        ],
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 49,
        name: "Chocolate Quente",
        image: product49,
        price: 18,
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 50,
        name: "Frozen",
        image: product50,
        price: 18,
        description: "Leite integral, base de iogurte, calda saborizada e gelo",
        flavors: ["Morango"],
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 51,
        name: "Chá Mate",
        image: product51,
        price: 9,
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 52,
        name: "Refrigerante",
        image: product52,
        options: [
          {
            id: 1,
            name: "Coca-cola(350ml)",
            price: 7,
          },
          {
            id: 2,
            name: "Coca-cola zero(350ml)",
            price: 7,
          },
          {
            id: 3,
            name: "Guaraná Antarctica(350ml)",
            price: 7,
          },
          {
            id: 4,
            name: "Guaraná Antarctica zero(350ml)",
            price: 7,
          },
          {
            id: 5,
            name: "Sprite fresh(500ml)",
            price: 8,
          },
        ],
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 53,
        name: "Energético",
        image: product53,
        options: [
          {
            id: 1,
            name: "Red Bull",
            price: 15,
          },
        ],
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 54,
        image: product54,
        name: "Soda Italiana",
        description: "Xarope, água com gás e gelo",
        price: 14,
        flavors: [
          "Cranberry",
          "Frutas vermelhas",
          "Limão siciliano",
          "Maça verde",
          "Melância",
          "Morango",
          "Romã",
        ],
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 56,
        name: "Suco",
        image: product56,
        price: 12,
        description: "Suco natural com gelo bem geladinho",
        flavors: ["Abacaxi", "Laranja", "Morango"],
        subCategories: ["Bebida", "Sem álcool"],
      },
      {
        id: 57,
        name: "Água",
        image: product57,
        price: 5,
        options: [
          {
            id: 1,
            name: "Com gás",
            price: 5,
          },
        ],
        subCategories: ["Bebida", "Sem álcool"],
      },
    ],
  },
];
