import { Fragment } from "react";
import SubCategory from "../sub-category/sub-category.component";

import "./sub-category-list.styles.scss";

const SubCategoryList = ({ subCategories }) => {
  return (
    <div className="sub-category-list">
      {subCategories.map((name) => {
        return (
          <Fragment key={name}>
            <SubCategory name={name} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default SubCategoryList;
