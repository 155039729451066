import image from "../../assets/components/about/about.jpg";

import "./about.styles.scss";

const About = () => {
  const paragraphs = [
    {
      id: 1,
      text: "O Nina Café de Casa é uma cafeteria inaugurada em novembro de 2023 na rua Gonçalo de Carvalho 129, a rua mais bonita do mundo, localizada em Porto Alegre. Com uma filosofia simples e poderosa de ser um lugar para todos. Sem preconceitos, abraçamos todas as pessoas de forma igual. Queremos que todos se sintam parte de uma grande família unida pela paixão por café e boa comida",
    },
    {
      id: 2,
      text: "O ambiente foi planejado como uma extensão da minha casa, trazendo a arte, a cor, os elementos rústicos, um pátio acolhedor, um jardim encantador e a presença animada da Nina, nossa West Terrier que é dona, a inspiração para o nome e anfitriã de quatro patas. A Nina tem mais de 9 anos e adora interagir com os clientes, no entanto, é importante destacar que ela tem uma dieta rigorosa. Com uma carinha encantadora, irá tentar seduzir os clientes para ganhar uma provinha dos pratos. Pedimos, gentilmente, que não a alimentem. Dê a ela carinho no lugar de comida. A segurança dela (e da comida de vocês 😅) é uma prioridade",
    },
    {
      id: 3,
      text: "Nossa cafeteria, oferece receitas de família, pão caseiro, geleias, tortas, torradas e brownies, todos feitos com amor e muito cuidado. Queremos proporcionar não apenas comida, mas uma experiência que traga nostalgia, lembranças de momentos em família, sensações de ser abraçado pelos bons sentimentos e a criação de novas memórias cheias de afeto",
    },
    {
      id: 4,
      text: "Além disso, temos tomadas distribuídas no salão e no pátio, Wi-Fi e uma equipe empenhada, pronta para tornar o seu homeoffice melhor",
    },
    {
      id: 5,
      text: "Venha nos visitar! Aqui no Nina não servimos apenas café, servimos afeto em forma de sabores",
    },
  ];

  return (
    <section className="about" id="about">
      <h2 className="about__title">Sobre</h2>
      <div className="standard-alignment">
        <div className="about__wrapper">
          <img
            className="about__image"
            src={image}
            alt="Du(dono do Nina) e sua mãe(Seni) estão atrás de um balcão com um pão caseiro feito por ele mesmo, e duas xícaras de café"
          />

          <div className="about__text">
            <div className="about__image-placeholder"></div>

            {paragraphs.map(({ id, text }) => {
              if (id < 4) {
                return <p key={id}>&emsp;{text}</p>;
              } else {
                return <p key={id}>{text}</p>;
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
